/* Generic Styles start here */
* {
  scrollbar-width: 7px;
  scrollbar-color: #cccccc transparent;
}

*::-webkit-scrollbar {
  width: 7px;
}
*::-webkit-scrollbar-track {
  background: transparent;
}
*::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}

@media (max-width: 480px) {
  * {
    scrollbar-width: 4px;
  }

  *::-webkit-scrollbar {
    width: 4px;
  }
}

a {
  cursor: pointer !important;
}

.form-control:focus {
  border-color: unset !important;
  box-shadow: unset !important;
  outline: none !important;
}

:focus {
  outline-width: 0px !important;
}

.near-full-opacity {
  opacity: 0.92 !important;
}

a,
a:hover {
  text-decoration: none;
  color: inherit;
}

.noPadding {
  padding: 0px !important;
}

body,
html {
  height: 100%;
}

ul,
li {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  background: none;
  border: none;
  padding: 0;
  color: inherit;
}

select {
  background: none;
  border: none;
}

/* Generic Styles end  */

.middle {
  text-align: center;
  margin: 2px 0px;
}

.middle input[type="radio"] {
  display: none;
}

.middle .box {
  width: 60px;
  height: 30px;
  background-color: #eff7ff;
  transition: all 0.25s ease;
  will-change: transition;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  position: relative;
  font-family: "Dax", sans-serif;
  font-weight: 400;
  border-radius: 50px;
  line-height: 36px;
  font-size: 14px;
  margin: 0 8px;
}

.middle input[type="radio"]:checked + .box {
  background-color: #0a87ff;
  box-shadow: 0 0 1vh lightblue !important;
}

.middle input[type="radio"]:checked + .box span {
  color: #fff;
  -webkit-transform: translateY(70px);
  transform: translateY(70px);
  top: -72px;
}

.middle .box span {
  position: absolute;
  transform: translate(0, 60px);
  left: 0;
  right: 0;
  transition: all 300ms ease;
  /* font-size: 1.5em; */
  user-select: none;
  color: #000;
  top: -58px;
  font-size: 19px;
}

.middle .box span {
  font-size: 16px;
  top: -62px;
}

.p_propsal_form_r_q_m {
  font-size: 16px !important;
  text-align: inherit;
  line-height: 27px !important;
  color: #000;
  font-weight: 400 !important;
  position: relative;
}

/* ............................................. */

.text-dark {
  color: #0d0d0d !important;
}

@media (max-width: 575px) {
  h1 {
    width: 100%;
  }
}

/* ,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,,, */

.icon {
  margin-top: 1px;
  display: inline-block;
  width: 54px;
  height: 54px;
  border-radius: 100%;
  background-size: 100px 50px;
  box-sizing: border-box;
  margin-left: 28px;
  margin-left: 4px !important;
  font-weight: 700;
  margin: 0 0px 0 21px;
}

/* ................ */

.btn:focus {
  border: none !important;
  box-shadow: none !important;
}

.rotate {
  animation: rotate linear 1s infinite;
}

/* .................... */

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

@keyframes loading {
  0% {
    left: -30%;
  }
  50% {
    left: 80%;
  }
  100% {
    left: -30%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.skeleton-loader {
  display: inline-block;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;
}
.skeleton-loader::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0,
    rgba(255, 255, 255, 0.2) 20%,
    rgba(255, 255, 255, 0.5) 60%,
    rgba(255, 255, 255, 0)
  );
  animation: shimmer 2s infinite;
  content: "";
}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.modal-content {
  border: none !important;
}

.only-desktop {
  display: block;
}

.shortlist-tooltip.tooltip-inner,
.shortlist-tooltip > *,
.shortlist-tooltip > .tooltip-inner {
  animation: none !important;
  font-size: 13px;
}

@media (max-width: 768px) {
  .only-desktop {
    display: none;
  }
}

.flip-card {
  background-color: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card .flip {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flip-card-back {
  transform: rotateY(180deg);
  position: absolute;
  top: 0;
  left: 0;
}

.blink-red {
  animation: blink-red 200ms linear infinite;
  -webkit-animation: blink-red 200ms linear infinite;
}

@keyframes blink-red {
  0% {
    background-color: rgb(251, 94, 94);
  }
  50% {
    background-color: darkred;
  }
  100% {
    background-color: rgb(251, 94, 94);
  }
}

.blink-green {
  animation: blink-green 200ms linear infinite;
  -webkit-animation: blink-green 200ms linear infinite;
}

@keyframes blink-green {
  0% {
    background-color: lightgreen;
  }
  50% {
    background-color: green;
  }
  100% {
    background-color: lightgreen;
  }
}

.blink-yellow {
  animation: blink-yellow 200ms linear infinite;
  -webkit-animation: blink-yellow 200ms linear infinite;
}

@keyframes blink-yellow {
  0% {
    background-color: yellow;
  }
  50% {
    background-color: gold;
  }
  100% {
    background-color: yellow;
  }
}
.tippy-popper{
  max-width: 240px;
}