/* Fonts Face Configuration */

/* Inter ================================== */
@font-face {
  font-family: "Inter";
  src: url("src/assets/fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("src/assets/fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Inter";
  src: url("src/assets/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("src/assets/fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* OPEN-SANS ======================= */
@font-face {
  font-family: "open-sans";
  src: url("src/assets/fonts/Open-Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "open-sans";
  src: url("src/assets/fonts/Open-Sans/OpenSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "open-sans";
  src: url("src/assets/fonts/Open-Sans/OpenSans-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "open-sans";
  src: url("src/assets/fonts/Open-Sans/OpenSans-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* POPPINS ========================== */
@font-face {
  font-family: "Poppins";
  src: url("src/assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* MONTSERRAT ====================== */
@font-face {
  font-family: "Montserrat";
  src: url("src/assets/fonts/Montserrat/Montserrat-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("src/assets/fonts/Montserrat/Montserrat-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("src/assets/fonts/Montserrat/Montserrat-SemiBold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Montserrat";
  src: url("src/assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* Gothic720 ====================== */
@font-face {
  font-family: "Gothic720";
  src: url("src/assets/fonts/Gothic720/tt0993m_.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
