:root {
  --heading_color: #384252;
  --subHeading_color: #4c5564;
  --body_color: #6b737f;
  --white_color: #f3f4f6;
  --error_text_color: #d62439;
  --success_text_color: #26b30a;
}

/* Weights */
.typography--weight-normal {
  font-weight: 400;
}
.typography--weight-medium {
  font-weight: 500;
}
.typography--weight-semiBold {
  font-weight: 600;
}
.typography--weight-bold {
  font-weight: 700;
}

/* Typography Roles (success, error, normal) */
.typography--role-success {
  color: var(--success_text_color) !important;
}
.typography--role-error {
  color: var(--error_text_color) !important;
}

/* Typography Color Type */
.typography--color-headingColor {
  color: var(--heading_color);
}
.typography--color-subHeadingColor {
  color: var(--subHeading_color);
}
.typography--color-bodyColor {
  color: var(--body_color);
}
.typography--color-whiteColor {
  color: var(--white_color);
}

/* Mobile view ================== */

.typography--variant-h1Big {
  font-size: 1.602rem;
}

.typography--variant-h1 {
  font-size: 1.424rem;
}

.typography--variant-h2 {
  font-size: 1.266rem;
}

.typography--variant-h3 {
  font-size: 1.125rem;
}

.typography--variant-h4 {
  font-size: 1rem;
}

.typography--variant-h5 {
  font-size: 0.889rem;
}

.typography--variant-h6 {
  font-size: 0.79rem;
}

.typography--variant-subheading1 {
  font-size: 0.702rem;
}

.typography--variant-subheading2 {
  font-size: 0.624rem;
}

.typography--variant-body1 {
  font-size: 0.624rem;
  margin-bottom: 0;
}

.typography--variant-body2 {
  font-size: 0.555rem;
  margin-bottom: 0;
}

/* Media for Tablet View  ==================== */
@media (min-width: 768px) {
  .typography--variant-h1Big {
    font-size: 1.802rem;
  }
  .typography--variant-h1 {
    font-size: 1.602rem;
  }

  .typography--variant-h2 {
    font-size: 1.424rem;
  }

  .typography--variant-h3 {
    font-size: 1.266rem;
  }

  .typography--variant-h4 {
    font-size: 1.125rem;
  }

  .typography--variant-h5 {
    font-size: 1rem;
  }

  .typography--variant-h6 {
    font-size: 0.889rem;
  }

  .typography--variant-subheading1 {
    font-size: 0.79rem;
  }

  .typography--variant-subheading2 {
    font-size: 0.702rem;
  }

  .typography--variant-body1 {
    font-size: 0.624rem;
    margin-bottom: 0;
  }

  .typography--variant-body2 {
    font-size: 0.555rem;
    margin-bottom: 0;
  }
}

/* Media Queries for Desktop View ==================== */

@media (min-width: 1024px) {
  .typography--variant-h1Big {
    font-size: 2.488rem;
  }

  .typography--variant-h1 {
    font-size: 1.802rem;
  }

  .typography--variant-h2 {
    font-size: 1.602rem;
  }

  .typography--variant-h3 {
    font-size: 1.424rem;
  }

  .typography--variant-h4 {
    font-size: 1.266rem;
  }

  .typography--variant-h5 {
    font-size: 1.125rem;
  }

  .typography--variant-h6 {
    font-size: 1rem;
  }

  .typography--variant-subheading1 {
    font-size: 0.889rem;
    position: relative;
  }

  .typography--variant-subheading2 {
    font-size: 0.79rem;
  }

  .typography--variant-body1 {
    font-size: 0.702rem;
    margin-bottom: 0;
  }

  .typography--variant-body2 {
    font-size: 0.624rem;
    margin-bottom: 0;
  }
}
